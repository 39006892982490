var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "px-6 pb-2 pt-6" },
    [
      _c(
        "vs-button",
        {
          staticClass: "w-full",
          on: {
            click: function ($event) {
              _vm.activePrompt = true
            },
          },
        },
        [_vm._v("Add Task")]
      ),
      _c(
        "vs-prompt",
        {
          attrs: {
            title: "Add Task",
            "accept-text": "Add Task",
            "button-cancel": "border",
            "is-valid": _vm.validateForm,
            active: _vm.activePrompt,
          },
          on: {
            cancel: _vm.clearFields,
            accept: _vm.addTodo,
            close: _vm.clearFields,
            "update:active": function ($event) {
              _vm.activePrompt = $event
            },
          },
        },
        [
          _c("div", [
            _c("form", [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col ml-auto flex" },
                  [
                    _c("feather-icon", {
                      staticClass: "cursor-pointer",
                      attrs: {
                        icon: "InfoIcon",
                        svgClasses: [
                          {
                            "text-success stroke-current":
                              _vm.taskLocal.isImportant,
                          },
                          "w-5",
                          "h-5 mr-4",
                        ],
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.taskLocal.isImportant = !_vm.taskLocal.isImportant
                        },
                      },
                    }),
                    _c("feather-icon", {
                      staticClass: "cursor-pointer",
                      attrs: {
                        icon: "StarIcon",
                        svgClasses: [
                          {
                            "text-warning stroke-current":
                              _vm.taskLocal.isStarred,
                          },
                          "w-5",
                          "h-5 mr-4",
                        ],
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.taskLocal.isStarred = !_vm.taskLocal.isStarred
                        },
                      },
                    }),
                    _c(
                      "vs-dropdown",
                      {
                        staticClass: "cursor-pointer flex",
                        attrs: { "vs-custom-content": "" },
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "TagIcon", svgClasses: "h-5 w-5" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                        _c(
                          "vs-dropdown-menu",
                          { staticStyle: { "z-index": "200001" } },
                          _vm._l(_vm.taskTags, function (tag, index) {
                            return _c(
                              "vs-dropdown-item",
                              {
                                key: index,
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                              },
                              [
                                _c(
                                  "vs-checkbox",
                                  {
                                    attrs: { "vs-value": tag.value },
                                    model: {
                                      value: _vm.taskLocal.tags,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.taskLocal, "tags", $$v)
                                      },
                                      expression: "taskLocal.tags",
                                    },
                                  },
                                  [_vm._v(_vm._s(tag.text))]
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "w-full mb-4 mt-5",
                      attrs: {
                        name: "title",
                        placeholder: "Title",
                        color: _vm.validateForm ? "success" : "danger",
                      },
                      model: {
                        value: _vm.taskLocal.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.taskLocal, "title", $$v)
                        },
                        expression: "taskLocal.title",
                      },
                    }),
                    _c("vs-textarea", {
                      attrs: { rows: "5", label: "Add description" },
                      model: {
                        value: _vm.taskLocal.desc,
                        callback: function ($$v) {
                          _vm.$set(_vm.taskLocal, "desc", $$v)
                        },
                        expression: "taskLocal.desc",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }