var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "border border-solid d-theme-border-grey-light rounded relative overflow-hidden",
      attrs: { id: "todo-app" },
    },
    [
      _c(
        "vs-sidebar",
        {
          staticClass: "items-no-padding",
          attrs: {
            parent: "#todo-app",
            "click-not-close": _vm.clickNotClose,
            "hidden-background": _vm.clickNotClose,
          },
          model: {
            value: _vm.isSidebarActive,
            callback: function ($$v) {
              _vm.isSidebarActive = $$v
            },
            expression: "isSidebarActive",
          },
        },
        [
          _c("todo-add-new"),
          _c(
            _vm.scrollbarTag,
            {
              key: _vm.$vs.rtl,
              tag: "component",
              staticClass: "todo-scroll-area",
              attrs: { settings: _vm.settings },
            },
            [
              _c("todo-filters", {
                on: { closeSidebar: _vm.toggleTodoSidebar },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "no-scroll-content border border-r-0 border-b-0 border-t-0 border-solid d-theme-border-grey-light no-scroll-content",
          class: { "sidebar-spacer": _vm.clickNotClose },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex d-theme-dark-bg items-center border border-l-0 border-r-0 border-t-0 border-solid d-theme-border-grey-light",
            },
            [
              _c("feather-icon", {
                staticClass:
                  "md:inline-flex lg:hidden ml-4 mr-4 cursor-pointer",
                attrs: { icon: "MenuIcon" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.toggleTodoSidebar(true)
                  },
                },
              }),
              _c("vs-input", {
                staticClass:
                  "vs-input-no-border vs-input-no-shdow-focus w-full",
                attrs: {
                  "icon-no-border": "",
                  size: "large",
                  "icon-pack": "feather",
                  icon: "icon-search",
                  placeholder: "Search...",
                },
                model: {
                  value: _vm.searchQuery,
                  callback: function ($$v) {
                    _vm.searchQuery = $$v
                  },
                  expression: "searchQuery",
                },
              }),
            ],
            1
          ),
          _c(
            _vm.scrollbarTag,
            {
              key: _vm.$vs.rtl,
              ref: "taskListPS",
              tag: "component",
              staticClass: "todo-content-scroll-area",
              attrs: { settings: _vm.settings },
            },
            [
              _c(
                "transition-group",
                {
                  staticClass: "todo-list",
                  attrs: { name: "list-enter-up", tag: "ul", appear: "" },
                },
                _vm._l(_vm.taskList, function (task, index) {
                  return _c(
                    "li",
                    {
                      key: String(_vm.currFilter) + String(task.id),
                      staticClass: "cursor-pointer todo_todo-item",
                      style: [{ transitionDelay: index * 0.1 + "s" }],
                    },
                    [
                      _c("todo-task", {
                        key: String(task.title) + String(task.desc),
                        attrs: { taskId: task.id },
                        on: {
                          showDisplayPrompt: function ($event) {
                            return _vm.showDisplayPrompt($event)
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.displayPrompt
        ? _c("todo-edit", {
            attrs: {
              displayPrompt: _vm.displayPrompt,
              taskId: _vm.taskIdToEdit,
            },
            on: { hideDisplayPrompt: _vm.hidePrompt },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }