var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "todo__filters-container" },
    [
      _c(
        "div",
        { staticClass: "px-6 py-4" },
        [
          _c(
            "router-link",
            {
              staticClass: "flex cursor-pointer",
              class: { "text-primary": _vm.todoFilter == "all" },
              attrs: { tag: "span", to: `${_vm.baseUrl}/all` },
            },
            [
              _c("feather-icon", {
                attrs: {
                  icon: "LayersIcon",
                  svgClasses: [
                    { "text-primary stroke-current": _vm.todoFilter == "all" },
                    "h-6 w-6",
                  ],
                },
              }),
              _c("span", { staticClass: "text-lg ml-3" }, [_vm._v("All")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("vs-divider"),
      _c(
        "div",
        { staticClass: "px-6 py-4" },
        [
          _c("h5", [_vm._v("Filters")]),
          _vm._l(_vm.todoFilters, function (filter) {
            return [
              _c(
                "router-link",
                {
                  key: filter.filter,
                  staticClass: "flex mt-6 cursor-pointer",
                  class: { "text-primary": _vm.todoFilter == filter.filter },
                  attrs: { tag: "span", to: `${_vm.baseUrl}/${filter.filter}` },
                },
                [
                  _c("feather-icon", {
                    attrs: {
                      icon: filter.icon,
                      svgClasses: [
                        {
                          "text-primary stroke-current":
                            _vm.todoFilter == filter.filter,
                        },
                        "h-6 w-6",
                      ],
                    },
                  }),
                  _c("span", { staticClass: "text-lg ml-3" }, [
                    _vm._v(_vm._s(filter.filterName)),
                  ]),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
      _c("vs-divider"),
      _c("div", { staticClass: "px-6 py-4" }, [
        _c("h5", [_vm._v("Labels")]),
        _c(
          "div",
          { staticClass: "todo__lables-list" },
          _vm._l(_vm.taskTags, function (tag, index) {
            return _c(
              "router-link",
              {
                key: index,
                staticClass:
                  "todo__label flex items-center mt-6 cursor-pointer",
                attrs: { tag: "span", to: `${_vm.baseUrl}/${tag.value}` },
              },
              [
                _c("div", {
                  staticClass: "ml-1 h-3 w-3 rounded-full mr-4",
                  class: "border-2 border-solid border-" + tag.color,
                }),
                _c(
                  "span",
                  {
                    staticClass: "text-lg",
                    class: { "text-primary": _vm.todoFilter == tag.value },
                  },
                  [_vm._v(_vm._s(tag.text))]
                ),
              ]
            )
          }),
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }